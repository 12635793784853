define([
"referrals/Referrals",
"eforms/Keyword",
"Patient",
"OUtils",
"OApp",
"OEncryption",
"dwr/interface/ERequestRpc",
"i18n",
"questionnaires/core/QuestionnaireApplication",
"questionnaires/core/QuestionnaireForm",
"js/ProgressNoteFormatter",
"bootstrap"
], function(Referrals, Keyword, Patient, OUtils, OApp, OEncryption, ERequestRpc, _i18n, QuestionnaireApplication, QuestionnaireForm, ProgressNoteFormatter) { "use strict";
var IntakePortal = function() {
	var eReqRef = null;
	var eReqCfg = null;
	var mainPanel = null;
	var finishedPanel = null;
	var retryPanel = null;
	var encryptedPt = null;
	var pt = null;
	var app = null;
	var rtLoader = null;
	var rt = null;
	var referral = null, referralRef = null;
	var submitRedirectURL = null;
	var inactivityTimer = null;
	var loadingIcon;
	var urlVars = OUtils.getURLVars();
	var locale = urlVars["locale"];
	var oceanSessionId = null;
	function loadSendReferral(cb) {
		require(["referrals/SendReferral"], cb);
	}
	loadSendReferral();
	
	function init() {
		eReqRef = OUtils.getURLVars()["eReqRef"];
		pt = new Patient();
		rtLoader = $.Deferred();
		
		mainPanel = $("#intakePortal");
		if (window.location === window.parent.location) {
			//not in an iframe
			mainPanel.removeClass("hideIfIFrame");
		}
		loadingIcon = mainPanel.findIt("#ipLoadingIcon").fadeTo(500, 0.01).fadeTo(3000, 0.9);
		finishedPanel = mainPanel.findIt("#finishedPanel");
		retryPanel = mainPanel.findIt("#retryPanel");
		retryPanel.findIt("#retryBtn").click(function() {
			retry();
		});
		OUtils.handleBadBrowser();
		OApp.init({activateReverseAjax: false, sessionTimeoutHandler: inactivityTimeout});
		resetInactivityTimeout(true);
		OUtils.i18nInit({ namespaces: ["intakePortal", "pn-pe"] }, function(t) {
			mainPanel.i18n();
		});
		loadERequestCfg(function() {
			var eFormQueue = [];
			for (var ii = 0; ii < eReqCfg.forms.length; ii++) {
				eFormQueue.push({ref: eReqCfg.forms[ii].ref, siteNum: eReqCfg.forms[ii].siteNum || eReqCfg.siteNum});
			}
			if (eFormQueue.length === 0) {
				showError({abort: true, msg: OUtils.t("We're sorry, this eRequest is not configured properly. Please contact your clinic.", "IntakePortal.js.alert_erequest_not_configured")});
				return;
			}
			ERequestRpc.loadRt(eReqCfg.referralTargetRef, function(rtIn) {
				resetInactivityTimeout(true);
				if (rtIn === null) {
					showError({abort: true, msg: OUtils.t("The site for this eRequest could not be located. Please check the Ocean Portal's ERequest settings.", "IntakePortal.js.error_erequest_not_located")});
					rtLoader.fail();
					return;
				}
				rt = rtIn;
				rtLoader.resolve();
			});

			var qPanel = mainPanel.findIt("#questionnairePanel");
			QuestionnaireApplication.load({
				tag : qPanel.show(),
				locale: locale || i18n.lng(),
				pt : pt,
				eFormQueue : eFormQueue,
				useFormMemory : false,
				encryptedPt : encryptedPt,
				subsequentPages: false,
				allowEFormSiteGuess: true,
				initialHeadingLevel: 1
			}, function(newApp) {
				app = newApp;
				app.addFormListener({
					finished: questionnaireComplete,
					formComplete: formComplete,
					formDisplayed: formDisplayed
				});
				oceanSessionId = app.createOceanSessionId();
				app.setInactivityTimeoutFunctions(resetInactivityTimeout);
				OUtils.hideWait();
				loadingIcon.remove();
			});
			submitRedirectURL = eReqCfg.redirectURL;
		});
		
		function loadERequestCfg(cb) {
			if (eReqRef === undefined) {
				lookupERequestCfg();
			} else {
				loadERequestCfgFromRpc();
			}
			
			function lookupERequestCfg() {
				var rtRef = OUtils.getURLVars()["rt"];
				if (!rtRef) {
					showError({abort: true, msg: OUtils.t("You must specify a referral target in the URL arguments (eg. rt=mysiteref).", "IntakePortal.js.error_specify_referral_target")});
					return;
				}
				
				ERequestRpc.lookupIntakePortalERequestConfig(rtRef, loadEFormQueueRefs(), function(eReqConfig) {
					if (eReqConfig === null) {
						window.location.replace("/intake/InvalidLink.html");
						return;
					}
					eReqCfg = eReqConfig;
					eReqRef = eReqCfg.ref;
					cb();
				});
				function loadEFormQueueRefs() {
					var eFormQueue = [];
					var i;
					for (i = 1; i <= 10; i++) {
						var formRef = OUtils.getURLVars()["form" + i];
						if (!formRef) {
							break;
						}
						eFormQueue.push(formRef);
					}
					return eFormQueue;
				}
			}
			function loadERequestCfgFromRpc() {
				ERequestRpc.getERequestConfig(eReqRef, function(eReqConfig) {
					if (eReqConfig === null) {
						window.location.replace("/intake/InvalidLink.html");
						return;
					}
					eReqCfg = eReqConfig;
					cb();
				});
			}
		}
	}
	function retry() {
		retryPanel.hide();
		submitERequest();
	}
	function questionnaireComplete(answeredEForms) {
		submitERequest();
	}
	
	function formComplete(eForm, answeredEForm, answeredEForms, savePromises) {
		if (eForm.dataSecurityMode == DataSecurityMode.anonymous || eForm.dataSecurityMode == DataSecurityMode.hybrid) {
			var d = $.Deferred();
			savePromises.push(d.promise());
			FormMemoryRpc.getSaltBase64(function (saltBase64) {
				OceanStudyRpc.getStudySalts([answeredEForm.eForm.ref], function (salts) {
					// update patient demographics before generating hash
					app.updatePatientWithChanges(new Patient.Note());
					pt.siteNum = rt.siteNum;
					var hash = OSymmetricEncryption.generateFormMemoryHash(pt, encryptedPt, salts[0], saltBase64);
					EncryptedPatientRpc.submitOnlineAnonAnswers(null, answeredEForm.convertToDto(), hash, oceanSessionId, function () {
						d.resolve();
					});
				});
			});
		}
	}
	function formDisplayed() {
		scrollToTop();
		mainPanel.find("input, select, .eFormQToggleButton, textarea").first().focus();
		function scrollToTop() {
			if ("parentIFrame" in window) {
			    parentIFrame.scrollTo(0, 0);
			    parentIFrame.size();
			}
		}
	}
	function inactivityTimeout() {
		OUtils.showNote(OUtils.t("Please click OK when you are ready to continue completing this form.", "webq.timeOut"), {
			title: OUtils.t("Session Inactivity", "webq.timeOutCaption"),
			callback: refreshSession,
			modal: true,
			dialogClass: "ui-dialog-no-close"
		});
		inactivityTimer = null;
		
		function refreshSession() {
			// this will re-establish the session through the eRequest token
			$.ajax({ url: "/portal/portalBlank.html?eReqRef=" + eReqRef, 
				cache: false,
				dataType: "html",
				success: function(response, status) {
					resetInactivityTimeout(true);
				},
				error: function() {
					showError({abort: true, msg: OUtils.t("Sorry, this eRequest is no longer available. Please check the source of the link.", "IntakePortal.js.alert_erequest_no_longer_available")});
				}
			});
		}
	}
	function resetInactivityTimeout(restart) {
		if (inactivityTimer) {
			clearTimeout(inactivityTimer);
			OUtils.hideWait();
		}
		if (restart) {
			// set timer for 25 minutes (I don't think these timers are very accurate...)
			inactivityTimer = setTimeout(inactivityTimeout, 25*60*1000);
		}
	}
	function createReferral(callback) {
		loadSendReferral(function(SendReferral) {
			referral = {
				referralRef: referralRef
			};
			referral.route = ReferralRoute.E_REFERRAL;
			referral.referralTargetRef = rt.ref;
			referral.referralTargetTitle = rt.title;
			referral.srcSiteNum = rt.siteNum;
			var answeredEForms = app.getAnsweredEForms();
			var answeredEFormsArray = answeredEForms.getAnsweredEFormsInOrder();
			var description = "";
			for (var i = 0; i < answeredEFormsArray.length; i++) {
				var eForm = answeredEFormsArray[i].eForm;
				if (description.length > 0) {
					description += "; ";
				}
				description += eForm.shortForm || eForm.title;
				if (i === 0) {
					referral.referralForm = {ref: eForm.ref, siteNum: eForm.siteNum};					
				}
			}
			referral.description = description;
			referral.status = ReferralStatus.SENT;
			referral.selfReferral = true;
			referral.eRequestSource = eReqCfg.name;
			referral.eRequestRef = eReqCfg.ref;
			
			var note = new ProgressNoteFormatter({
				patient: app.getCurrentPatient(),
				answeredEForms: answeredEForms
			}).buildProgressNote(pt);
			var ptUpdate = app.updatePatientWithChanges(note);
			pt.ptUpdate = ptUpdate;
			SendReferral.importReferralFieldsFromForm({referral: referral, pt: pt, rt: rt, completedForms: ptUpdate.completedForms})
			.then(function () {
				if (pt.demographics && rt.regionalAuthoritySiteNum) {
					return SendReferral.createFingerprint(rt.regionalAuthoritySiteNum, pt.demographics.hn, pt.demographics.hnProv);
				}
				return null;
			})
			.then(function(fingerprint) {
				referral.fingerprint = fingerprint;
				encrypt();
				ERequestRpc.submit({referral: referral}, {
					callback: function(referralArg) {
						resetInactivityTimeout(false);
						referral = referralArg;
						callback(referral);
					},
					exceptionHandler: function(e) {
						console.log(e);
						showError({abort: true, msg: e});
					}
				});
			});
		});
		function encrypt() {
			if (!encryptedPt) {
				encryptedPt = {};
				encryptedPt.oneTimeKey = OSymmetricEncryption.generateOneTimeKey();	//generate a new one-time key since this is presumably the first encryption for the patient
			}
			if (rt.publicKey) {
				referral.oneTimeKeyEncryptedWithTargetPublicKey =
					OAsymmetricEncryption.encryptWithPublicKey(rt.publicKey, encryptedPt.oneTimeKey);
			}
			referral.encryptedPtData = OSymmetricEncryption.encryptPt(pt, encryptedPt.oneTimeKey);
			referral.hashed = OSymmetricEncryption.hashPtFields(pt);
		}
	}
	function submitERequest() {
		if (!app || !app.scanAndVerifyAnswers()) {
			showError({msg: OUtils.t("Sorry, the form is missing some information; please review your answers and try again.", "IntakePortal.js.alert_form_missing_information")});
			return;
		}
		resetInactivityTimeout(false);
		OUtils.showWait();
		retryPanel.delay(10000).fadeIn(5000);
		rtLoader.then(function() {//load the rt first
			createReferral(function() {
				finished();
			});
		}, function() {
			showError({msg: OUtils.t("Sorry your submission could not be saved due to an error: the referral target failed to load.", "IntakePortal.js.error_submission_not_saved")});
		});
		function finished() {
			OUtils.hideWait();
			retryPanel.remove();
			if (submitRedirectURL) {
				var keywordContext = new Keyword.KeywordContext({pt: pt, answeredEForms: app.getAnsweredEForms()});
				submitRedirectURL = Keyword.replaceKeywords(submitRedirectURL, keywordContext).replace(" ", "%20");
				if (submitRedirectURL.indexOf("http") === -1) {
					submitRedirectURL = "https://" + submitRedirectURL;
				}
				window.location = submitRedirectURL;
			} else {
				finishedPanel.findIt("#defaultFinishMsg").show();
				finishedPanel.fadeIn(500);
			}
		}
	}
	function showError(spec) {
		OUtils.hideWait();
		var msg = spec.msg, title = spec.title || OUtils.t("Error", "IntakePortal.js.error"), abort = spec.abort, callback = spec.callback;
		if (abort) {
			mainPanel.find(".center-block").hide();
			var errorPanel = mainPanel.findIt("#errorPanel").show();
			errorPanel.findIt("#errorPanelMsg").text(msg);
			errorPanel.findIt("#errorPanelHeading").text(title);
		}
		else {
			OUtils.showError(msg, callback, {title: title});			
		}
	}
	return {
		init: init
	};
}();
$(function() {
	IntakePortal.init();
	OUtils.translateHtml($("html"));
});
});